import React from "react";
import { Box } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function PromotionsCarousel({ property }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xs: "auto", // Pequeñas pantallas (teléfonos)
          lg: "60vh", // Grandes pantallas
        },
        overflow: "hidden", // Asegura que el contenido no se desborde
      }}
    >
      <Carousel
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={6000}
        keyBoardControl
        showDots
        arrows
        ssr
        swipeable
        draggable
      >
        {property.images.map((image, index) => (
          <Box key={index} sx={{ width: "100%", height: "100%" }}>
            <img
              src={`https://andesconectionsbackend.onrender.com/${image}`}
              alt={`Imagen ${index + 1} de ${property.name}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // Ajusta la imagen al contenedor sin distorsión
              }}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
